import React, { Component } from 'react';
import style from './upload.module.scss';

export default class Upload extends Component {

	constructor(props) {
		super(props);
		this.field = React.createRef();
		this.state = {
			files: '',
			value: [],
			progress: 0,
			active: false,
			formValue: '',
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleTrigger = this.handleTrigger.bind(this);
		this.onChange = this.props.onChange;
	}

	updateProgress(evt) {
		this.setState({
			progress: evt.loaded/evt.total*100,
		});
		if (evt.loaded === evt.total) {
			this.setState({
				formValue: JSON.stringify(this.state.value),
			});
		}
	}

	uploadComplete(evt){
		let name = (this.props.field && this.props.field.name ? this.props.field.name : this.props.name);
		const parsed = JSON.parse(evt.target.response);
		this.setState({
			formValue: parsed.data[0].filename,
		});
		this.props.setFieldValue(name, this.state.formValue);
	}

	handleChange(evt) {
		const element = evt.target;
		let result;
		if (this.onChange)result = this.onChange.apply(this, [evt]);
		if (result === false) {
			evt.preventDefault();
			return;
		}
		if (result !== false) {
			fetch(`${process.env.GATSBY_ZMS_API_URL}upload_token/`)
				.then(async function(response){
					if (!response.ok){
						const body = await response.json();
						throw new Error(body.errorMessage);
					}
					return response.json();
				})
				.then((json) => {
					const token = (json.data && json.data.length && 'token' in json.data[0] ? json.data[0].token : '');
					const request = new XMLHttpRequest();
					request.upload.addEventListener('progress', this.updateProgress.bind(this));
					request.addEventListener('load', this.uploadComplete.bind(this));
					request.open('POST', `${process.env.GATSBY_ZMS_API_URL}upload/?token=${token}`);
					const data = new FormData();
					let fileList = [];
					for (const file of element.files) {
						fileList.push(`${file.name} (${this.humanSize(file.size)})`);
						data.append('file', file, file.name);
					}
					this.setState({
						files: fileList.join(', '),
						value: element.files,
						active: true,
						progress: 0,
					});
					request.send(data);
				})
				.catch(function(err){
					alert(err);
				})
			;
		}
	}

	handleTrigger() {
		let name = (this.props.field && this.props.field.name ? this.props.field.name : this.props.name);
		document.getElementById(name).click();
	}

	humanSize(size, block = 1000){
		const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		let i = -1;
		do {
			size /= block;
			i++;
		} while (size > block);
		return `${Math.max(size, 0.1).toFixed(1)} ${units[i]}`;
	}

	render() {
		let name = (this.props.field && this.props.field.name ? this.props.field.name : this.props.name);
		let multiple = this.props.multiple||false;
		let buttonLabel = this.props.buttonLabel||'Browse…';
		let label = this.props.label;
		let errorCondition = (this.props.form && this.props.form.touched[name] && this.props.form.errors[name] ? true : (this.props.errorCondition !== undefined ? this.props.errorCondition : false));
		let errorMessage = (this.props.form && this.props.form.errors[name] ? this.props.form.errors[name] : this.props.errorMessage);
		let { className } = this.props;
		className = (className||'').split(' ');
		className.push(style.container);
		className = className.filter((item) => item);
		return (
			<div className={className.join(' ')}>
				<label htmlFor={name}>{label}</label>
				<div className={style.fieldContainer}>
					<button type="button" className={style.button} onClick={this.handleTrigger}><div className={ style.mockField }>{this.state.files}</div><div className={style.buttonLabel}>{buttonLabel}</div></button>
					<div className={ [style.progress, (this.state.active ? style.active : null)].join(' ') }><div className={style.progressFill} style={{ width: `${this.state.progress}%` }} /><div className={style.progressLabel}>{this.state.progress.toFixed(0)}%</div></div>
					<input type="file" className={style.inputField} id={name} ref={this.field} name={`${name}_fileInput`} multiple={multiple} onChange={this.handleChange} accept={this.props.accept} />
					<div className={ [style.error, (errorCondition ? style.active : '')].join(' ') }>{errorMessage}</div>
				</div>
				<input type="hidden" name={name} value={this.state.formValue} />
			</div>
		)
	}
}