import React, { Component } from "react"
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { graphql } from 'gatsby'
import DatePicker from 'components/form/datepicker/'
import Layout from "templates/basic/"
import SEO from 'components/seo'
import Upload from 'components/form/upload/';
import State from 'components/form/state/';
import TextInput from 'components/form/textinput/';
import Number from 'components/form/number/';
import ReCAPTCHA from 'react-google-recaptcha';
import Select from 'components/form/select/';
import style from './grantApplication.module.scss';

export default class VolunteerForm extends Component {

	constructor(props) {
		super(props);
		this.recaptchaComplete = this.recaptchaComplete.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.timerID = null;

		this.phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
		this.zipRegExp = /\d{5}-?(\d{4})?/
		this.formSchema = Yup.object().shape({
			firstName: Yup.string()
				.required('Please enter your first name.'),
			lastName: Yup.string()
				.required('Please enter your last name.'),
			email: Yup.string()
				.email('Please enter a valid email address.')
				.required('Please enter your email address.'),
			phone: Yup.string()
				.matches(this.phoneRegExp, 'Please enter a valid phone number.')
				.required('Please enter a phone number.'),
			organization: Yup.string()
				.required('Please enter the name of the organization.'),
			address: Yup.string()
				.required('Please enter the your address.'),
			city: Yup.string()
				.required('Please enter the your city.'),
			state: Yup.string()
				.required('Please select the state you are located in.'),
			zip: Yup.string()
				.matches(this.zipRegExp, 'Please enter a valid zip code')
				.required('Please enter the your zip code.'),
			orgPhone: Yup.string()
				.matches(this.phoneRegExp, 'Please enter a valid phone number.')
				.required('Please enter the phone number of the organization.'),
			contact: Yup.string()
				.required('Please enter the name of the contact person regarding this form.'),
			contactEmail: Yup.string()
				.email('Please enter a valid email address.')
				.required('Please enter the email of the contact person regarding this form.'),
			orgType: Yup.string()
				.required('Please select the organization type.'),
			tshirtSize: Yup.string()
				.required('Please select your t-shirt size.'),
			dateWorked: Yup.date()
				.required('Please select the date you worked.'),
			hoursWorked: Yup.number()
				.required('Please enter the amount of hours you worked.'),
			locationOfWorkPerformed: Yup.string()
				.required('Please enter the location of the work performed.'),
			briefDescription: Yup.string()
				.required('Please enter a brief description of the work performed.'),
			signedForm: Yup.string(),
			eventPhoto: Yup.string()
		});
		this.state = {
			recaptcha: '',
			recaptchaError: false,
			success: false,
		}
		this.initialValues = {
			firstName: '',
			lastName: '',
			phone: '',
			email: '',
			address: '',
			city: '',
			state: '',
			zip: '',
			orgPhone: '',
			contact: '',
			contactEmail: '',
			orgType: '',
			dateWorked: '',
			locationOfWorkPerformed: '',
			briefDescription: '',
			signedForm: '',
			eventPhoto: '',
			env: 'VOLUNTEER_FORM',
			mailto: 'email1',
		}
	}

	recaptchaComplete(value) {
		this.setState({
			recaptcha: value,
			recaptchaError: false,
		});
	}

	componentWillUnmount() {
		if (this.timerID !== null) clearTimeout(this.timerID);
		this.timerID = null;
	}

	submitForm(values, { setSubmitting, resetForm }) {
		for (const field in values) {
			if (Object.hasOwnProperty.call(values, field)) {
				const value = values[field];
				if (Object.prototype.toString.call(value) === '[object Date]') {
					// Create a date object from the timestamp
					var date = new Date(value);

					// Create a list of names for the months
					var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

					// return a formatted date
					values[field] = months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
				}
			}
		}
		if (!this.state.recaptcha) {
			setSubmitting(false);
			this.setState({
				recaptchaError: true,
			});
			return;
		}
		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(Object.assign({}, values, { recaptcha: this.state.recaptcha })),
		};
		fetch(`${process.env.GATSBY_ZMS_API_URL}contact/mail/`, options)
			.then(async function (response) {
				if (!response.ok) {
					const body = await response.json();
					throw new Error(body.errorMessage);
				}
				return response.json();
			})
			.then((json) => {
				this.setState({
					success: true,
				});
				if (this.timerID) clearTimeout(this.timerID);
				this.timerID = setTimeout(() => {
					this.timerID = null;
				}, 3000);

			})
			.catch(function (err) {
				console.log(err);
			})
			.finally(function () {
				setSubmitting(false);
				resetForm();
			})
			;
	}

	render() {
		const options = [
			'Welfare',
			'Education',
			'Environment',
			'Healthcare',
			'Other'
		];

		const tshirtOptions = [
			"Small",
			"Medium",
			"Large",
			"X-Large",
			"2X",
		]

		const filesAccepted = ".jpg,.jpeg,.png,.gif,.txt,.docx,.doc,.xls,.xlsx,.zip,.pdf,.mov,.mp4,.ogg,.ogv,.vcf,.rtf"

		return (
			<>
				<Layout image="/userfiles/Foundation Forms/foundation header v3.jpg" title="Danos WORKS" enableDanosFoundation={false} enableFooterTabs={true}>
					<section className="intro">
						<div className="grid-container">
							<div className="grid-x">
								<div className="cell small-12">
									<h1>Volunteer Form</h1>
									<p className={style.disclaimer}>*denotes required fields</p>
								</div>
							</div>
							<div className="grid-x grid-margin-x">
								<div className="cell small-12">
									<Formik
										initialValues={this.initialValues}
										validationSchema={this.formSchema}
										onSubmit={this.submitForm}
									>
										{({ values, setFieldValue, isSubmitting }) => (
											<Form id="grantApplicationForm" method="post" encType="multipart/form-data">

												<div className={`${this.state.success ? style.hide : 'showMe'}`}>

													<div className={style.sectionTitle}>Employee Information</div>
													<div className="grid-x grid-margin-x medium-up-2">
														<Field className="cell" value={values.firstName} name="firstName" label="First Name:*" component={TextInput} />
														<Field className="cell" value={values.lastName} name="lastName" label="Last Name:*" component={TextInput} />
														<Field className="cell" value={values.email} name="email" label="Email Address:*" component={TextInput} />
														<Field className="cell" value={values.phone} name="phone" label="Phone:*" component={TextInput} />
													</div>

													<div className={style.sectionTitle}>Non-Profit Organization Information</div>
													<div className="grid-x grid-margin-x">
														<Field className="cell" value={values.organization} name="organization" label="Name of Non-Profit Organization:*" component={TextInput} />
														<Field className="cell" value={values.address} name="address" label="Address:*" component={TextInput} />
													</div>

													<div className="grid-x grid-margin-x medium-up-3">
														<Field className="cell" value={values.city} name="city" label="City:*" component={TextInput} />
														<Field className="cell" value={values.state} name="state" label="State:*" component={State} />
														<Field className="cell" value={values.zip} name="zip" label="Zip:*" component={TextInput} maxLength={10} />
													</div>

													<div className="grid-x grid-margin-x medium-up-2">
														<Field className="cell" value={values.orgPhone} name="orgPhone" label="Organization Phone:*" component={TextInput} />
														<Field className="cell" value={values.contact} name="contact" label="Organization Contact Name:*" component={TextInput} />
														<Field className="cell" value={values.contactEmail} name="contactEmail" label="Organization Email Address:*" component={TextInput} />
														<div className="cell">
															<div className="grid-x grid-margin-x medium-up-2">
																<Field className="cell" name="orgType" label="Organization Type:*" options={options} placeholder="-- Please select --" component={Select} />
																<Field className="cell" name="tshirtSize" label="T-Shirt (Unisex):*" options={tshirtOptions} placeholder="-- Please select --" component={Select} />
															</div>
														</div>
													</div>

													<div className={style.sectionTitle}>Tell Us What You Did!</div>
													<div className="grid-x grid-margin-x medium-up-2">
														<DatePicker className="cell" name="dateWorked" label="Date Worked:*" />
														<Field className="cell" value={values.hoursWorked} name="hoursWorked" type="number" label="Hours Worked:*" component={Number} />
														<Field className="cell" value={values.locationOfWorkPerformed} name="locationOfWorkPerformed" label="Location of Work Performed:*" component={TextInput} />
													</div>

													<div className="grid-x grid-margin-x medium-up-2">
														<Field className="cell" name="signedForm" setFieldValue={setFieldValue} label="Signed Danos WORKS Volunteer Form (Optional)" component={Upload} accept={filesAccepted} />
														<Field className="cell" name="eventPhoto" setFieldValue={setFieldValue} label="Photo from Event (Optional)" component={Upload} accept={filesAccepted} />
													</div>

													<Field name="briefDescription" value={values.briefDescription} label="Brief Description of Work Performed:*" maxLength={1000} component={TextInput} lines={5} />

													<div style={{ marginTop: '2rem', marginBottom: '1rem', position: 'relative' }}><div style={{ zIndex: 2, position: 'relative' }}><ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY} onChange={this.recaptchaComplete} /></div><div className={[style.error, (this.state.recaptchaError ? style.active : '')].join(' ')}>You must complete the ReCAPTCHA</div></div>

													<button className="button primary" type="submit" disabled={isSubmitting}>Submit Application</button>

												</div>

												{<div className={[style.success, (this.state.success ? style.showSuccess : '')].join(' ')}>Thank you for your application and the good work you do in the community.</div>}
											</Form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</section>
				</Layout>
				<SEO title={'Danos WORKS | Danos Foundation'} />
			</>
		)
	}
}